import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Avatar,
  Box,
  Button,
  Menu,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { DeleteOutline, EditOutlined, Share } from "@mui/icons-material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { makeStyles } from "@mui/styles";
import DeleteAlert from "../AlertBox/DeleteAlert";
import { environmentDetails } from "../../../src/environment/environment";
import {
  getQuestionPackage,
  deleteQuestionPackage,
  editQuestionPackageStatus,
  shareJobInCareerActionFn,
} from "../../Redux/QuestionPacakge/action";
import { useDispatch, useSelector } from "react-redux";
import ApiAlert from "../AlertBox/ApiAlert";
import { IOSSwitchButton } from "../ReusableComponents/SwitchButtons";
import { columns, jobSharingMenu } from "../../constants/openingTab";
import { useDebounce } from "../../hooks/useDebounce";
import { Link } from "react-router-dom";
import img_not_found from "../../assets/img_not_found.jpg";
import { roleModulePermissions } from "../../utils/roleInstance";
import {
  dsShortLogo,
  naukriLogo,
  indeedLogo,
  linkedInLogo,
} from "../../assets";
import { privateAxiosInstance } from "../../utils/axiosInstance";
import { accessTokenHeadersObject } from "../../utils/headers";

const apiUrl = environmentDetails.apiUrl;

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "& .MuiTableCell-root": {
      // borderRight: `1px solid ${theme.palette.grey[200]}`,
      borderRight: `1px solid gray`,
      borderBottom: "none",
      padding: 5,
      paddingTop: 8,
      paddingBottom: 8,
      cursor: "pointer",
      minWidth: 25,
      //this works but only for cell hover, not the full row
      "&:hover": {
        backgroundColor: "red",
      },
    },
  },
}));

// function shareJobInCareer(data) {
//   return privateAxiosInstance.patch(
//     "/questionnaire/updateOpeningForCareerPortal",
//     data,
//     {headers:{...accessTokenHeadersObject()}}
//   );
// }

const OpenningsTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openingsData, setOpeningsData] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setalertcontent] = useState("");
  const [alertStatus, setAlertStatus] = useState(Boolean);
  const [copyStatus, setCopyStatus] = useState(Boolean);
  const [jobIdCopyStatus, setJobIdCopyStatus] = useState(0);
  const { debouncedValue } = useDebounce(props.search);
  const [shareEnchorEl, setShareEnchorEl] = useState(null);
  const [sharingJobOpening, setSharingJobOpening] = useState(null);

  const [switchOpen, setSwitchOpen] = useState(false);
  const [switchInactive, setSwitchInactive] = useState(null);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value, 10);
    setPage(0);
  };

  const handleClickOpen = (data) => {
    if (data?.jobRole?.role === "Any Open Position") {
      setAlert(true);
      setAlertStatus(false);
      setalertcontent("You can't delete Any Open Postion campaign");
      return;
    }
    setOpen(true);
    setIdToDelete(data.openingId);
  };

  const handleClickOpenSwich = (data) => {
    setSwitchOpen(true);
    setSwitchInactive(data);
  };

  const switchHandleClose = () => {
    setSwitchOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const questionPackage = useSelector(
    (state) => state.questionPackageReducer.questionsPackage
  );
  const questionPackageLength = useSelector(
    (state) => state.questionPackageReducer.length
  );

  // console.log("Questions", questionPackage);
  //console.log("questionPackageLength", questionPackageLength);

  const editCampaignStatus = (data) => {
    // console.log(data.openingId);
    // console.log(!data.openingStatus);
    const id = data.openingId;
    const status = !data.openingStatus;
    dispatch(editQuestionPackageStatus(id, status))
      .then((res) => {
        // console.log("status", res.payload.status);
        if (res.payload.status === 200) {
          setAlert(true);
          setAlertStatus(res.payload.data.status);
          setalertcontent(res.payload.data.message);
        } else {
          setAlert(true);
          setAlertStatus(res.payload.response.data.status);
          setalertcontent(res.payload.response.data.message);
        }
        switchHandleClose();
        dispatch(getQuestionPackage());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openingsDataDelete = () => {
    dispatch(deleteQuestionPackage(idToDelete))
      .then((res) => {
        setAlert(true);
        setAlertStatus(res.payload.status);
        setalertcontent(res.payload.message);
        handleClose();
        dispatch(getQuestionPackage());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOpeningData = (page, rowsPerPage) => {
    dispatch(getQuestionPackage(page + 1, rowsPerPage, debouncedValue));
  };
  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    }
  }, [rowsPerPage, debouncedValue && openingsData?.dataLength !== undefined]);
  useEffect(() => {
    getOpeningData(page, rowsPerPage);
    // dispatch(getQuestionPackage(page + 1, rowsPerPage));
  }, [page, rowsPerPage, debouncedValue]);

  const AlerthandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert(false);
  };

  const handleCopyImage = (imageLink, jobId) => {
    if (navigator.clipboard.writeText(imageLink)) {
      setCopyStatus(true);
      setJobIdCopyStatus(jobId);
    } else {
      setCopyStatus(false);
    }
  };

  const shareJob = (data) => {
    console.log(data, "share job");
  };

  // Check Role Permission
  const [openingEditAction, setOpeningEditAction] = useState("");
  const [openingDeleteAction, setOpeningDeleteAction] = useState("");

  // const checkOpeningEditAction = async () => {
  //   const roleData = {
  //     roleModule: "Openings",
  //   };
  //   let rolePermission = await roleModulePermissions.checkUserRoles(
  //     roleData,
  //     "edit"
  //   );
  //   // return rolePermission
  //   setOpeningEditAction(rolePermission);'

  // };

  // const checkOpeningDeleteAction = async () => {
  //   const roleData = {
  //     roleModule: "Openings",
  //   };
  //   let rolePermission = await roleModulePermissions.checkUserRoles(
  //     roleData,
  //     "delete"
  //   );
  //   // return rolePermission
  //   setOpeningDeleteAction(rolePermission);
  // };

  // useEffect(() => {
  //   checkOpeningEditAction();
  //   checkOpeningDeleteAction();
  // }, [page]);
  // Check Role Permission
  useEffect(() => {
    const checkOpeningAction = async () => {
      const roleData = {
        roleModule: "Openings",
      };

      // Execute both async functions concurrently
      const [editPermission, deletePermission] = await Promise.all([
        roleModulePermissions.checkUserRoles(roleData, "edit"),
        roleModulePermissions.checkUserRoles(roleData, "delete"),
      ]);

      // Update state with the results
      setOpeningEditAction(editPermission);
      setOpeningDeleteAction(deletePermission);
    };

    checkOpeningAction();
  }, [page]);

  const onShareMenuClose = () => {
    setShareEnchorEl(null);
    setSharingJobOpening(null);
  };
  const onShareMenuOpne = (row) => {
    console.log("On share event called", row);
    setSharingJobOpening(row);
    // setShareEnchorEl(event.currentTarget);
  };
  //console.log("question packages   ",questionPackage)

  const handleShareJobOnChange = (e) => {
    let { checked, name } = e.target;
    if (name === "Career Portal") {
      let data = {
        sharedCareerOpening: checked,
        openingId: sharingJobOpening.openingId,
      };
      dispatch(shareJobInCareerActionFn(data))
      .then((res)=>{
        if(res.type==="SHARE_JOB_IN_CAREER_SUCCESS"){
          onShareMenuClose()
          setAlert(true);
          setAlertStatus(true);
          setalertcontent(res.payload.message);
          getOpeningData();
        }  
      })
      .catch(err=>{
        console.log("error");
        
      })
    }
    console.log("handleShareJobOnChange event==> ", { checked, name });
  };
  return (
    <>
      {/* <SearchFeild
            buttonName="Add Job Role"
            placeholder="Search Job Role"
            textFeildWidth="80%"
            width="100%"
            large="25px"
            // Navigate={handleOpen}
          />   */}
      {alert && (
        <ApiAlert
          open={alert}
          snackbaronClose={AlerthandleClose}
          alertonClose={AlerthandleClose}
          alertType={alertStatus ? "success" : "error"}
          Content={alertContent}
        />
      )}
      <Paper sx={{ width: "100%" }}>
        <TableContainer
          sx={{
            //minHeight:"415px",
            maxHeight: "calc(100vh - 175px)",
          }}
        >
          <Table
            sx={{
              minWidth: 650,
            }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return (
                    <TableCell
                      sx={{
                        // backgroundColor: "rgb(30, 13, 97)",
                        // color: "rgb(254, 154, 37)",
                        backgroundColor: "primary.main",
                        color: "secondary.main",
                        minWidth: column.minWidth,
                      }}
                      key={column.id}
                      align={column.align}
                    >
                      {column.id == "Action"
                        ? (openingEditAction != undefined &&
                            openingEditAction != "") ||
                          (openingDeleteAction != undefined &&
                            openingDeleteAction != "")
                          ? column.label
                          : ""
                        : column.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {questionPackage.map((el, index) => (
                <TableRow
                  key={index}
                  // sx={{
                  //   opacity: el?.jobRoleStatus ? null : "0.7",
                  //   backgroundColor: el?.jobRoleStatus
                  //     ? null
                  //     : "primary.disabledBackground",
                  // }}
                  sx={{
                    backgroundColor:
                      el.openingStatus === false ? "#dddddd" : "block",
                  }}
                >
                  <TableCell component="th" scope="row" align="right">
                    {index + 1}
                  </TableCell>
                  {/* <TableCell align="left">{el?.companies.join(", ")}</TableCell> */}
                  <TableCell align="left">{el?.recCompanyName}</TableCell>
                  {/* <TableCell align="left">{el.jobRole?.role}</TableCell> */}
                  <TableCell align="left">{el?.jobRoleTitle}</TableCell>
                  <TableCell align="center" style={{ width: "10%" }}>
                    <Stack direction={"row"} gap={4}>
                      <Tooltip title="Job image" arrow placement="bottom">
                        <img
                          src={el.jobRole?.image}
                          onError={(event) => {
                            event.target.onerror = null;
                            event.target.src = img_not_found;
                          }}
                          style={{
                            width: "35%",
                            border: "2px solid #fe9a25",
                            borderRadius: "8px",
                          }}
                        ></img>
                      </Tooltip>

                      <Box style={{ marginTop: "15px" }}>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleCopyImage(el.jobRole?.image, el?.openingId)
                          }
                        >
                          {copyStatus && el?.openingId === jobIdCopyStatus ? (
                            <FileDownloadDoneIcon
                              style={{ color: "#1e0d61" }}
                            />
                          ) : (
                            <Tooltip
                              title="Copy Image Link"
                              arrow
                              placement="top"
                            >
                              <ContentCopyRoundedIcon
                                style={{ color: "#fe9a25" }}
                              />
                            </Tooltip>
                          )}
                        </p>
                      </Box>
                    </Stack>
                  </TableCell>
                  {/* <TableCell align="center">{el?.jobRole.image}</TableCell> */}
                  <TableCell align="left">
                    {el.selectedQuestionList?.length == 1
                      ? el.selectedQuestionList[0]?.Question
                      : el.selectedQuestionList[0]?.Question +
                        "+" +
                        (el.selectedQuestionList?.length - 1) +
                        " more"}
                  </TableCell>
                  <TableCell align="center" sx={{ cursor: "pointer" }}>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      {openingEditAction != "" &&
                      openingEditAction != undefined ? (
                        <Tooltip arrow title={"Edit Opening"} placement="top">
                          <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={`/selected_question/${
                              el.jobRoleId
                            }/${el.companyIds.join(",")}`}
                          >
                            <EditOutlined
                              className={classes.deleteIconButton}
                              fontSize="medium"
                              //style={{ marginLeft: 8 }}
                              sx={{
                                display:
                                  el.openingStatus === true ? "block" : "none",
                              }}
                            />
                          </Link>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {openingDeleteAction != "" &&
                      openingDeleteAction != undefined ? (
                        <Tooltip title="Delete" arrow placement="top">
                          <DeleteOutline
                            className={classes.deleteIconButton}
                            fontSize="medium"
                            style={{ marginLeft: 8 }}
                            onClick={() => handleClickOpen(el)}
                            sx={{
                              display:
                                el.openingStatus === true ? "block" : "none",
                            }}
                            // || el.jobRoleTitle === "Any Open Position" ?
                            // sx={{display:"none"}}
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {/* TODO: */}
                      <>
                        <Tooltip title="Share Job" arrow placement="top">
                          <Share
                            className={classes.shareIconButton}
                            fontSize="medium"
                            style={{ marginLeft: 8 }}
                            onMouseOver={(event) => {
                              onShareMenuOpne(el);
                              setShareEnchorEl(event.currentTarget);
                            }}
                          />
                        </Tooltip>
                      </>
                      {openingDeleteAction != "" &&
                      openingDeleteAction != undefined ? (
                        <Tooltip
                          title={el.openingStatus ? "Disable" : "Enable"}
                          arrow
                          placement="top"
                        >
                          <IOSSwitchButton
                            size="small"
                            //onClick={() => handleClickOpenSwich(el)}
                            onClick={
                              el.openingStatus === false
                                ? () => editCampaignStatus(el)
                                : () => handleClickOpenSwich(el)
                            }
                            checked={el.openingStatus === true}
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
              {/* ))} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={questionPackageLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <DeleteAlert
          open={open}
          onClose={handleClose}
          handleClose={handleClose}
          handleDelete={openingsDataDelete}
          rightButton={"Delete"}
          header={"Delete"}
          message={"Are you sure you want to delete?"}
        />
        <DeleteAlert
          open={switchOpen}
          onClose={switchHandleClose}
          handleClose={switchHandleClose}
          handleDelete={() => editCampaignStatus(switchInactive)}
          rightButton={"Inactive"}
          header={"Inactive"}
          message={"Are you sure you want to make  this item inactive?"}
        />
        <ShareMenu
          enchorEl={shareEnchorEl}
          onClose={onShareMenuClose}
          onChange={handleShareJobOnChange}
          openedRow={sharingJobOpening}
        />
      </Paper>
    </>
  );
};

export default OpenningsTable;

function ShareMenu({ enchorEl, onClose, onChange, openedRow }) {
  return (
    <Menu
      open={Boolean(enchorEl)}
      anchorEl={enchorEl}
      onClose={onClose}
      transformOrigin={{ horizontal: "center", vertical: "bottom" }}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      MenuListProps={{ onMouseLeave: onClose }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mb: 0.5,
          mt: "-15px",
          // border: "1px solid blue",
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
            // border: "3px solid teal",
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            bottom: -15,
            right: 80,
            width: 0,
            height: 0,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
            border: "0px solid red",
          },
        },
      }}
    >
      <Box
        sx={{
          width: "200px",
          paddingX: "20px",
          // border: "1px solid green",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
        }}
      >
        <Typography textAlign={"center"}>Share On</Typography>
        {jobSharingMenu.map((elem) => (
          <Box
            key={elem.title}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "96%",
            }}
          >
            {/* <Typography variant="body1">Career Portal</Typography> */}
            <LogoTextBox text={elem.title} logo={elem.logo} />
            <Switch
              name={elem.title}
              disabled={elem.disabled}
              onChange={onChange}
              checked={elem.title==="Career Portal"&& openedRow?.sharedCareerOpening===true}
            />
          </Box>
        ))}
        {/* <Button
          variant="contained"
          size="small"
          sx={{ textTransform: "none", backgroundColor: "secondary.main" }}
        >
          Share
        </Button> */}
      </Box>
    </Menu>
  );
}

function LogoTextBox({ text, logo }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
      }}
    >
      <img
        style={{
          height: "20px",
          width: "20px",
          borderRadius: "5px",
          overflow: "hidden",
        }}
        src={logo}
        alt={text}
      />
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
}
