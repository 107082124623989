import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Stack,
  TablePagination,
  TextField,
  Typography,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Autocomplete,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteOutline, EditOutlined, InfoOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import DeleteAlert from "../AlertBox/DeleteAlert";
import { useDispatch, useSelector } from "react-redux";
import {
  editCompanyStatus,
  getHiringCompanyData,
  getHiringCompanyThroughData,
  postHiringCompanyInFlow,
} from "../../Redux/HiringCompany/action";
import {
  postRecruitmentCompanyInFlow
} from "../../Redux/RecruitmentCompany/action";
import { Controller, useForm } from "react-hook-form";
import PeopleToNotifyRepeater from "../../Pages/PeopleToNotifyRepeater.jsx";
import TemplateRepeater from "../../Pages/TemplateRepeater.jsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "../../Pages/RecruiterTabDashboard.css";
import { getCountryData } from "../../Redux/Country/action";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { IOSSwitchButton } from "../ReusableComponents/SwitchButtons.js";
import {
  editRecruitmentCompanyActionFn,
  getIflowChildCompanyUsingParentId,
  getRecruitmentCompanyActionFn,
} from "../../Redux/RecruitmentCompany/action.js";
import {
  columns,
  getMasterCompanyNameByMasterCompany,
  validatePeopleToBeNotified,
} from "../../constants/recruiterTab";
import ReuseableLabel from "../ReusableComponents/ReuseableLabel.jsx";
import EmailRepeaters from "../Repeaters/EmailRepeaters.jsx";
import {
  getChildCompanies,
  getCompanyNumber,
} from "../../Redux/Templates/action";
import {
  getOpeningByCompanyActionFn
} from "../../Redux/QuestionPacakge/action";
import { removePlusFromPhoneNumber } from "../../utils/helperFunctions.js";
import { roleModulePermissions } from "../../utils/roleInstance";
import IButtonModal from "../Modals/IButtonModal.jsx";
import { useColorMode } from "../../hooks/useColorMode.js";
import ApiAlert from "../AlertBox/ApiAlert.jsx";

const useStyles = makeStyles((theme) => ({
  editIconButton: {
    "&:hover": {
      color: "rgb(254, 154, 37)", // Set EditIcon hover text color here
    },
  },
  deleteIconButton: {
    "&:hover": {
      color: "red", // Set deleteIconButton hover text color here
    },
  },
  tableRow: {
    "& .MuiTableCell-root": {
      borderRight: `1px solid ${theme.palette?.grey[200]}`,
      borderBottom: "none",
      padding: 5,
      paddingTop: 8,
      paddingBottom: 8,
      cursor: "pointer",
      minWidth: 25,
      //this works but only for cell hover, not the full row
      "&:hover": {
        backgroundColor: "red",
      },
    },
  },
  sticky: {
    position: "sticky",
    right: 0,
  },
}));

const RecruiterTable = ({
  recruitmentData,
  // recruitmentCompanyData,
  deleteRecruiterCompany,
  handleChangeSwitch,
  open,
  setOpen,
  editRecruiterCompanyDetails,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  recruitmentDataLength,
  countryDetails,
  countrySelected,
}) => {
  const classes = useStyles();
  const [editModal, setEditModal] = React.useState(false);
  const [masterCompanyVal, setMasterCompanyVal] = useState({});
  const [selectedEditData, setSelectedEditData] = React.useState({});
  // const [idToDelete, setIdToDelete] = useState(null);
  const [switchInactive, setSwitchInactive] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDesc, setShowDesc] = useState("");

  const dispatch = useDispatch();
  const { getThemeColor } = useColorMode();
  const hiringCompanies = useSelector(
    (state) => state.hiringCompanyReducer.hiringCompany
  );

  const [templateClick, setTemplateClick] = useState(true);
  const [childMetaCompany, setChildMetaCompany] = useState([]);
  const [recruiterMetaCompany, setRecruiterMetaCompany] = useState([]);
  const [metaCompanyDetails, setMetaCompanyDetails] = useState([]);

  useEffect(() => {
    dispatch(getChildCompanies()).then((res) => {
      setMetaCompanyDetails(res.payload);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = (data) => {
    setOpen(true);
    setSwitchInactive(data);
  };

  const handleClose = () => {
    setOpen(false);
    setTemplateClick(true);
  };

  useEffect(() => {
    dispatch(getHiringCompanyData(1, 5000));
  }, []);
  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    }
  }, [recruitmentDataLength]);
  //console.log("recruitmentData", recruitmentData);
  const rows = recruitmentData.filter((el) => el.companyType != "master");

  const handleEditRecruiterCompany = (row) => {
    // console.log("row", row);
    setSelectedEditData(row);
    let masterCompanyValue = hiringCompanies.filter(
      (company) => company.CompanyId === row.masterCompanyId
    );
    setMasterCompanyVal(
      masterCompanyValue.length > 0
        ? masterCompanyValue[0]
        : { id: 0, name: "No Hiring company", CompanyId: "" }
    );

    if (row.recruiterMetaCompany != undefined) {
      const recruiterCompanyData = metaCompanyDetails.filter(
        (val) => val.id === row.recruiterMetaCompany
      );
      setRecruiterMetaCompany(recruiterCompanyData);
    } else {
      setRecruiterMetaCompany("");
    }

    if (row.childMetaCompany != undefined) {
      const childCompanyData = metaCompanyDetails.filter(
        (val) => val.id === row.childMetaCompany
      );
      setChildMetaCompany(childCompanyData);
    } else {
      setChildMetaCompany("");
    }

    setEditModal(true);
  };
  // console.log("wertyuiop[", recruitmentData);

  // Check Role Permission
  const [recruiterEditAction, setRecruiterEditAction] = useState("");
  const [recruiterDeleteAction, setRecruiterDeleteAction] = useState("");

  const checkRecruiterEditAction = async () => {
    const roleData = {
      roleModule: "Recruitment Company",
    };
    let rolePermission = await roleModulePermissions.checkUserRoles(
      roleData,
      "edit"
    );
    // return rolePermission
    setRecruiterEditAction(rolePermission);
  };

  const checkRecruiterDeleteAction = async () => {
    const roleData = {
      roleModule: "Recruitment Company",
    };
    let rolePermission = await roleModulePermissions.checkUserRoles(
      roleData,
      "delete"
    );
    // return rolePermission
    setRecruiterDeleteAction(rolePermission);
  };

  useEffect(() => {
    checkRecruiterEditAction();
    checkRecruiterDeleteAction();
  }, [page]);
  // Check Role Permission

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        // backgroundColor: "red",
        //overflow: "scroll",
      }}
    >
      <Paper sx={{ width: "100%" }}>
        <TableContainer
          //className={classes.height}
          sx={{
            //maxHeight: 515
            // height: "80vh",
            maxHeight: "70vh",
          }}
        >
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => {
                  return (
                    <TableCell
                      sx={{
                        // backgroundColor: "rgb(30, 13, 97)",
                        // color: "rgb(254, 154, 37)",
                        backgroundColor: "primary.main",
                        color: "secondary.main",
                        minWidth: column.minWidth,
                      }}
                      key={index}
                      align={column.align}
                      className={
                        column.id === "Action" ? classes.sticky : "null"
                      }
                    >
                      {column.id === "Action"
                        ? (recruiterEditAction != undefined &&
                            recruiterEditAction != "") ||
                          (recruiterDeleteAction != undefined &&
                            recruiterDeleteAction != "")
                          ? column.label
                          : ""
                        : column.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, index) => (
                <TableRow
                  key={index}
                  //sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  sx={{
                    backgroundColor:
                      row.companyStatus === false ? "#dddddd" : "block",
                  }}
                >
                  <TableCell component="th" scope="row" align="right">
                    {page * rowsPerPage + index + 1}
                  </TableCell>
                  <TableCell align="left">{row?.name}</TableCell>
                  <TableCell align="left">
                    {row?.website == "" ? "N/A" : row?.website}
                  </TableCell>
                  <TableCell align="left">
                    {row?.description === "" ? (
                      "N/A"
                    ) : row?.description?.length <= 27 ? (
                      row?.description
                    ) : (
                      <>
                        {row?.description?.slice(0, 20) + "...."}
                        {row?.description ? (
                          <Tooltip
                            title={"Click to view full description."}
                            arrow
                          >
                            <IconButton
                              onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setShowDesc(row?.description);
                              }}
                            >
                              <InfoOutlined sx={{ fontSize: "15px" }} />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                        <IButtonModal
                          type={"text"}
                          element={showDesc}
                          anchorEl={anchorEl}
                          setAnchorEl={setAnchorEl}
                        />
                      </>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {row?.masterCompanyName ? row?.masterCompanyName : "N/A"}
                  </TableCell>
                  <TableCell align="left">
                    {row?.phone_number ? row?.phone_number : "N/A"}
                  </TableCell>
                  <TableCell align="left">
                    {row?.phone_number_id ? row?.phone_number_internal : "N/A"}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.sticky}
                    sx={{
                      cursor: "pointer",
                      backgroundColor:
                        row.companyStatus === true
                          ? "primary.background"
                          : "#dddddd",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent={"center"}
                      alignItems={"center"}
                      // position={"sticky"}
                      // spacing={1}
                      spacing={row.companyStatus ? 2 : 5}
                    >
                      {recruiterEditAction != "" &&
                      recruiterEditAction != undefined ? (
                        <Tooltip
                          title="Edit Recruitment Company"
                          arrow
                          placement="top"
                        >
                          <EditOutlined
                            fontSize="medium"
                            //className={classes.editIconButton}
                            onClick={() => handleEditRecruiterCompany(row)}
                            // style={{display:"none"}}

                            sx={{
                              display:
                                row.companyStatus === true ? "block" : "none",
                            }}
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {/* <DeleteOutline
                        fontSize="medium"
                        className={classes.deleteIconButton}
                        onClick={() => handleClickOpen(row.CompanyId)}
                        sx={{
                          display:
                            row.companyStatus === false ? "block" : "none",
                        }}
                      /> */}
                      {recruiterDeleteAction != "" &&
                      recruiterDeleteAction != undefined ? (
                        <Tooltip
                          title={row.companyStatus ? "Disable" : "Enable"}
                          arrow
                          placement="top"
                        >
                          <IOSSwitchButton
                            size="small"
                            onClick={
                              row.companyStatus === false
                                ? () => handleChangeSwitch(row)
                                : () => handleClickOpen(row)
                            }
                            checked={row.companyStatus === true}
                          />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          // rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={recruitmentDataLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <DeleteAlert
        open={open}
        onClose={handleClose}
        handleClose={handleClose}
        handleDelete={() => handleChangeSwitch(switchInactive)}
        rightButton={"Inactive"}
        header={"Inactive"}
        message={
          "This will make all the campaigns associated with this company inactive. Are you sure you want to make  this item inactive?"
        }
      />
      <EditRecruiterCompanyModal
        open={editModal}
        setOpen={setEditModal}
        data={selectedEditData}
        editRecruiterCompanyDetails={editRecruiterCompanyDetails}
        masterCompanyVal={masterCompanyVal}
        hiringCompanies={hiringCompanies}
        countryDetails={countryDetails}
        recruiterMetaData={recruiterMetaCompany}
        childMetaData={childMetaCompany}
        defaultCountry={countrySelected}
      />
    </Box>
  );
};

export default RecruiterTable;

////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Edit Recruitment Company(MODAL)

export const schema = yup.object().shape({
  name: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z&])[\w&,().:; -]*[A-Za-z&][\w&,().:; -]*$/,
      "Invalid recruitment company (& , ( ) . : ; - are allowed with alphabetic characters)"
    )
    .test(
      "not-only-special-characters",
      "not-only-special-characters",
      (value) => {
        return /[^\W_]/.test(value);
      }
    )
    .nullable(),
  website: yup
    .string()
    .required("Company Website is required")
    .matches(/^(http|https|www)/, "Invalid company website format"),
  companyForHint: yup
    .string()
    .required("Company abbreviation is required")
    // .matches(
    //   /^[A-Za-z& 0-9]*[A-Za-z][A-Za-z& 0-9]*$/,
    //   'Must include at least one alphabetic character. "&" is allowed.'
    // )
    .test(
      "not-only-numbers",
      "Value must include at least one non-numeric character",
      (value) => {
        return /\D/.test(value);
      }
    ),
  phone_number: yup
    .string()
    .required("WhatsApp candidate connect # is required ")
    .matches(/^\d+$/, "Phone number must contain only numbers")
    .min(10, "Phone number must be at least 10 to 15 characters")
    .max(15, "Phone number must be at least 10 to 15 characters"),
  phone_number_id: yup
    .string()
    .required("WhatsApp candidate connect meta ID is required")
    .matches(/^\d+$/, "Phone number must contain only numbers"),
  phone_number_internal: yup
    .string()
    .required("WhatsApp recruiter connect # is required ")
    .matches(/^\d+$/, "Phone number internal must contain only numbers")
    .min(10, "Phone number must be at least 10 to 15 characters")
    .max(15, "Phone number must be at least 10 to 15 characters"),
  phone_number_id_internal: yup
    .string()
    .required("WhatsApp recruiter connect meta ID is required ")
    .matches(/^\d+$/, "Phone number id internal must contain only numbers"),
  reapplyDuration: yup.string().required("Reapply duration is required"),
  // linkedinPage: yup
  //   .string()
  //   .required()
  //   .matches(
  //     /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/,
  //     "Invalid LinkedIn page link"
  //   ),
  // instagramPage: yup
  //   .string()
  //   .matches(
  //     /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]+\/?$/,
  //     "Invalid Instagram page link"
  //   ),
  // careerPage: yup
  //   .string()
  //   .matches(
  //     /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.careerpage\.com\/?$/,
  //     "Invalid Career page link"
  //   ),
  // xHandle: yup
  //   .string()
  //   .matches(
  //     /^(https?:\/\/)?(www\.)?[a-zA-Z0-9_-]+\.com\/x\/[a-zA-Z0-9_-]+\/?$/,
  //     "Invalid X Handle page link"
  //   ),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "80%",
  overflow: "scroll",
  width: "55%",
  bgcolor: "background.paper",
  border: "0px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

// recruiter company edit modal
const EditRecruiterCompanyModal = ({
  open,
  setOpen,
  data,
  editRecruiterCompanyDetails,
  masterCompanyVal,
  hiringCompanies,
  countryDetails,
  recruiterMetaData,
  childMetaData,
  defaultCountry,
}) => {
  var flag = 0;
  const dispatch = useDispatch();
  const { getThemeColor } = useColorMode();
  const [masterCompanyIdVal, setMasterCompanyIdVal] = useState({});
  const [masterCompanyName, setMasterCompanyName] = useState("");
  const [rCompanyName, setRCompanyName] = useState("");
  const [rCompanyWebsite, setRCompanyWebsite] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [phoneNumberID, setPhoneNumberID] = useState("");
  // const [phoneNumberInternal, setPhoneNumberInternal] = useState("");
  // const [phoneNumberInternalID, setPhoneNumberInternalID] = useState("");
  const [newSubmit, setNewSubmit] = useState(false);
  const [recruiterCompanyDecLength, setRecruiterCompanyDecLength] = useState(0);
  const [notify, setNotify] = useState([{ cname: "", cphone: "", email: "" }]);
  const [template, setTemplate] = useState([
    {
      templateIndex: "",
      templateName: "",
      paramNumber: "",
      params: [],
      verbiage: "",
    },
  ]);
  const [emailValidate, setEmailValidate] = useState(false);

  const emailInitialState = [{ email: "" }];

  // const [countryDetails, setCountryDetails] = useState([]);
  const [countrySelected, setCountrySelected] = useState(defaultCountry);
  const [countryInternalSelected, setCountryInternalSelected] = useState("");
  const [phonePrefix, setPhonePrefix] = useState("");
  const [phonePrefixInternal, setPhonePrefixInternal] = useState("");
  const [repeaterError, setRepeaterError] = useState(false);
  const [peopleToNotifyAlert, setPeopleToNotifyAlert] = useState(null);
  const [emailNotify, setEmailNotify] = useState([{ email: "" }]);

  const [metaCompanyDetails, setMetaCompanyDetails] = useState([]);
  const [childCompanySelected, setChildCompanySelected] = useState("");
  const [recruiterCompanySelected, setRecruiterCompanySelected] = useState("");
  const [childCompanyDetails, setChildCompanyDetails] = useState("");
  const [recruiterCompanyDetails, setRecruiterCompanyDetails] = useState("");

  useEffect(() => {
    dispatch(getChildCompanies()).then((res) => {
      setMetaCompanyDetails(res.payload);
    });
  }, []);

  useEffect(() => {
    if (childCompanySelected != "") {
      dispatch(getCompanyNumber(childCompanySelected)).then((res) => {
        let childDetails = res.payload[0];
        if (
          childDetails != undefined &&
          childDetails["display_phone_number"] != undefined
        ) {
          let childCompanyArr = childDetails["display_phone_number"].split(" ");
          setCountrySelected(removePlusFromPhoneNumber(childCompanyArr[0]));
          setChildCompanyDetails({
            display_phone_number: childCompanyArr[1] + childCompanyArr[2],
            internal_id: childDetails["id"],
            id: childCompanySelected,
          });
        }
      });
    }
  }, [childCompanySelected]);

  useEffect(() => {
    if (recruiterCompanySelected != "") {
      dispatch(getCompanyNumber(recruiterCompanySelected)).then((res) => {
        let recruiterDetails = res.payload[0];
        if (
          recruiterDetails != undefined &&
          recruiterDetails["display_phone_number"] != undefined
        ) {
          let recruitmentCompanyArr =
            recruiterDetails["display_phone_number"].split(" ");
          setCountryInternalSelected(recruitmentCompanyArr[0]);
          setRecruiterCompanyDetails({
            display_phone_number:
              recruitmentCompanyArr[1] + recruitmentCompanyArr[2],
            internal_id: recruiterDetails["id"],
            id: recruiterCompanySelected,
          });
        }
      });
    }
  }, [recruiterCompanySelected]);

  const initailState = {
    name: "",
    website: "",
    description: "",
    masterCompanyId: "",
    masterCompanyName: "",
    companyForHint: "",
    phone_number_id: "",
    phone_number_internal: "",
    phone_number: "",
    phone_number_id_internal: "",
    linkedinPage: "",
    instagramPage: "",
    careerPage: "",
    xHandle: "",
    //emailIdToListen: "",
    // reapplyDuration: "",
    //peopleToBeNotified:notify,
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: initailState,
    resolver: yupResolver(schema),
  });
  //console.log("data111: ", data);
  const handleDescriptionKeyUp = (event) => {
    setRecruiterCompanyDecLength(event.target.value.length);
  };
  const handleClose = () => {
    setOpen(false);
    setNewSubmit(false);
    setCountrySelected("");
    setCountryInternalSelected("");
    setTemplateClick(true);
    setConditionCheck(true);
    setChildCompanyDetails("");
    setRecruiterCompanyDetails("");
    // setRecruiterMetaCompany("");
    // setChildMetaCompany("");
  };

  const phoneNumberId = watch("phone_number_id");
  const phoneNumberInternal = watch("phone_number_internal");
  const phoneNumber = watch("phone_number");
  const phoneNumberIdInternal = watch("phone_number_id_internal");

  const [templateClick, setTemplateClick] = useState(true);
  const [conditionCheck, setConditionCheck] = useState(true);
  const [valuex, setValuex] = useState(null);
  // const [iflowCompanyData, setIflowCompanyData] = useState("");
  const [iflowCompanyId, setIflowCompanyId] = useState("");
  const [iflowRecCompanyId, setIflowRecCompanyId] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertContent, setalertcontent] = useState("");
  const [openingStatus, setOpeningStatus] = useState("");
  const [alertStatus, setAlertStatus] = useState(Boolean);

  const handleTemplate = () => {
    const formValues = getValues();

    if (formValues.phone_number == "") {
      setValue("phone_number", childCompanyDetails.display_phone_number);
      setValue("phone_number_id", childCompanyDetails.internal_id);
      setValue(
        "phone_number_internal",
        recruiterCompanyDetails.display_phone_number
      );
      setValue("phone_number_id_internal", recruiterCompanyDetails.internal_id);
    }

    if (templateClick === false) {
      if (
        formValues.phone_number?.length >= 10 &&
        formValues.phone_number_id?.length >= 10 &&
        formValues.phone_number_internal?.length >= 10 &&
        formValues.phone_number_id_internal?.length >= 10
      ) {
        setTemplateClick(true);
        setConditionCheck(true);
      } else {
        setConditionCheck(false);
      }
    } else if (templateClick === true) {
      setTemplateClick(false);
    }
  };
  // console.log("DATA",data);
  // console.log("editRecruiterCompanyDetails",editRecruiterCompanyDetails)

  const iflowChildCompanyData = useSelector(
    (state) => state.recruitmentReducer?.iflowChildCompany
  );
  const iflowHiringcompanyData = useSelector(
    (state) => state.hiringCompanyReducer.iflowHiringCompany
  );
  console.log("masterCompanyIdVal", masterCompanyIdVal);
  // console.log("IFLOWCOMPANYDATA",iflowChildCompanyData)
  useEffect(() => {
    // console.log("EDITCHILDCOM",masterCompanyIdVal)
    
    if (
      masterCompanyIdVal !== null &&
      masterCompanyIdVal !== undefined &&
      masterCompanyIdVal !== ""
    ) {
      // console.log("EDITCHILDCOM",masterCompanyIdVal);
      if (masterCompanyIdVal?.CompanyId != undefined) {
        dispatch(getHiringCompanyThroughData(masterCompanyIdVal?.CompanyId))
          .then((res) => {
            if (res?.idFlowHiringCompany?.id) {
              dispatch(
                getIflowChildCompanyUsingParentId(res?.idFlowHiringCompany?.id)
              );
              setIflowCompanyId(res?.idFlowHiringCompany?.id);
              // console.log(
              //   "getHiringCompanyThroughData",
              //   res?.idFlowHiringCompany?.id
              // );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [masterCompanyIdVal]);

  // console.log(" masterCompanyIdVal.CompanyId", masterCompanyIdVal.CompanyId);
  // console.log("masterCompanyIdVal.name",masterCompanyIdVal.name)
  // console.log("valuex",valuex)
  const handleiFlowData = (valuex) => {
    var iflowCompID = ''
    if(iflowRecCompanyId){
      // console.log('++++',iflowRecCompanyId)
      iflowCompID = iflowRecCompanyId
    }
    else{
      // console.log('++++',data)
      iflowCompID = data.iFlowCompanyId
    }
    // console.log('+++iflowCompDetails+++', data)
    // console.log('++++iflowCompanyID++++', iflowCompID)
    dispatch(
      // postRecruitmentCompanyInFlow({
        postHiringCompanyInFlow({
        resource: {
          name: valuex,
          code: null,
          friendlyname: valuex,
          parentid: iflowCompanyId,
          comptype: "CUSTOMER",
          contacts: [],
          aliases: [],
          vendorsapid: [],
          customersapid: [],
          accountManagers: [],
          otherEmails: [],
          otherPhoneNumbers: [],
          partnerLevel: "",
          addresses: [
            {
              line: "",
              country: "India",
              state: "",
              city: "",
              postalcode: "",
              type: "PA",
              friendlyaddress: "",
            },
          ],
          group: null,
          primaryEmailId: "",
          primaryPhoneNumber: "",
          registered: "India",
          employeeName: "",
          employeeAddress: "",
          dob: "",
          aadhaarId: "",
          panId: "",
          companyId: iflowCompID,
          gstinIds: [],
          ieCode: "",
          blackList: false,
          isactive: true,
          aadhaar: {
            aadhaarId: "",
            name: "",
            address: "",
            gender: "",
            care_of: "",
            dob: "",
            email: "",
            id: "",
            message: "",
            mobile_hash: "",
            photo_link: "",
            ref_id: "",
            split_address: null,
            status: "",
            year_of_birth: "",
          },
          pan: {
            id: "",
            name: "",
            dob: "",
            fathersName: "",
          },
          flags: {
            mobilemsa: null,
            voicemsa: null,
            smsmsa: null,
            iotmsa: null,
            nda: null,
            ndaiot: null,
          },
          processName: "Initiation Process Pre-Screening",
        },
      })
    )
    .then((res) => {
      if(res.dataIflowRecruitmentCompany != undefined){
        let recRes = res.dataIflowRecruitmentCompany.data
        // console.log(recRes)
        // console.log(recRes.data)
        /* if(recRes[data] != '' && recRes[data] != null && recRes[data] != undefined){
          console.log(recRes[data])
        } */
        if(recRes.data){
          if(typeof recRes.data == 'number'){
            setIflowRecCompanyId(recRes.data)
          }
          else{
            setAlert(true);
            setAlertStatus(true);
            setalertcontent('Company Already Exists!');
          }
        }
        else{
          setIflowRecCompanyId(iflowRecCompanyId)
        }
        
        // setIflowRecCompanyId('')
        // console.log("dataGET", res.dataIflowHiringCompany);
        /* dispatch(getHiringCompanyThroughData(res?.dataIflowHiringCompany))
          .then((res) => {
            // console.log("helllooejdidbd", res?.idFlowHiringCompany?.code);
            setIflowCompanyData(res?.idFlowHiringCompany?.code);
          })
          .catch((err) => {
            console.log(err);
          }); */
      }
      else{
        setIflowRecCompanyId(iflowRecCompanyId)
        setAlert(true);
        setAlertStatus(true);
        setalertcontent('Company Already Exists!');
      }
    })
  };

  const AlerthandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  const onSubmit = (dataToEdit) => {
    setValue("phone_number", childCompanyDetails.display_phone_number);
    setValue("phone_number_id", childCompanyDetails.internal_id);
    setValue(
      "phone_number_internal",
      recruiterCompanyDetails.display_phone_number
    );
    setValue("phone_number_id_internal", recruiterCompanyDetails.internal_id);

    let res = validatePeopleToBeNotified(notify);
    if (!res.status) {
      setPeopleToNotifyAlert(res.message);
      setRepeaterError(true);
      return;
    }
    setRepeaterError(false);
    setPeopleToNotifyAlert(null);
    if (rCompanyName === "" || masterCompanyIdVal === null) {
      // console.log("not Ready to update some data key missing");
      return;
    }

    flag = false;
    emailNotify.forEach((el) => {
      if (el.isDuplicate === true) {
        //setEmailValidate(true);
        //return;
        flag = true;
        return;
      }
    });
    if (flag === true) {
      setEmailValidate(true);
      // console.log("sahna");
      return;
    }

    setEmailValidate(false);

    var finalTemplateValues = {};
    template.forEach((val) => {
      finalTemplateValues[val.templateIndex] = {
        name: val.templateName,
        params: val.params.toString(),
        verbiage: val.verbiage,
      };
    });

    let newData = {
      ...dataToEdit,
      masterCompanyId: masterCompanyIdVal.CompanyId,
      masterCompanyName: masterCompanyIdVal.name,
      peopleToBeNotified: notify,
      templates: finalTemplateValues,
      emailIdToListen: emailNotify,
      iFlowCompanyId: iflowRecCompanyId
    };

    newData.phone_number_prefix = countrySelected;
    newData.phone_number_internal_prefix = countryInternalSelected;
    newData.recruiterMetaCompany = recruiterCompanySelected;
    newData.childMetaCompany = childCompanySelected;
    editRecruiterCompanyDetails(newData);
    setTemplateClick(true);
    setOpen(false);
    // setNewSubmit(false);
  };

  const compareStrings = (a, b) => {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();
    return a < b ? -1 : a > b ? 1 : 0;
  };

  let menustr = "";
  let phoneNumberPrefix = "";
  let phoneNumberInternalPrefix = "";
  const countryListDetails = useSelector(
    (state) => state.countryReducer.country
  );
  // Changing the priority of the Country Data
  // console.log(countryListDetails)
  //const countryListDetails = []
  let countryData = countryListDetails;

  let newCountryData = [];
  //console.log("yyyyyyy",newCountryData)
  countryData.forEach((resp) => {
    if (resp.numeric_code == "356") {
      newCountryData.push(resp);
    }
    if (resp.numeric_code == "840") {
      newCountryData.push(resp);
    }
  });
  newCountryData.sort(function (a, b) {
    return compareStrings(a.name, b.name);
  });
  let newCountryData1 = countryData;
  newCountryData1.sort(function (a, b) {
    return compareStrings(a.name, b.name);
  });
  newCountryData1.forEach((res) => {
    newCountryData.push(res);
  });
  // Changing the priority of the Country Data

  useEffect(() => {
    if (peopleToNotifyAlert) {
      setTimeout(() => {
        setPeopleToNotifyAlert(null);
      }, 400);
    }
  }, [peopleToNotifyAlert ? true : false]);
  // console.log("xxxxxxxxxxxx",emailNotify)
  useEffect(() => {
    reset(data);
    setOpeningStatus(false)
    // Check the Opening
    // console.log(data)
    if(open){
      dispatch(getOpeningByCompanyActionFn(data.name)).then((res) => {
        // setMetaCompanyDetails(res.payload);
        // console.log(res.payload)
        let openingDetails = res.payload
        if(openingDetails != undefined){
          setOpeningStatus(true)
        }
      });
    }
    // Check the Opening
    setTemplateClick(false);
    setRecruiterCompanyDecLength(data?.description?.length || 0);
    setRCompanyName(data.name);
    setMasterCompanyIdVal(masterCompanyVal);
    // setRecruiterCompanySelected(data?.childMetaCompany);
    // setChildCompanySelected(data?.recruiterMetaCompany);
    setEmailNotify([{ email: "" }]);
    setNotify([{ cname: "", cphone: "", email: "" }]);
    setTemplate([
      {
        templateIndex: "",
        templateName: "",
        paramNumber: "",
        params: [],
        verbiage: "",
      },
    ]);

    if (data.phone_number) {
      setValue("phone_number", data.phone_number);
    }

    if (data.phone_number_id) {
      setValue("phone_number_id", data.phone_number_id);
    }

    if (data.phone_number_internal) {
      setValue("phone_number_internal", data.phone_number_internal);
    }

    if (data.phone_number_id_internal) {
      setValue("phone_number_id_internal", data.phone_number_id_internal);
    }

    if (data.emailIdToListen != undefined) {
      if (data.emailIdToListen.length > 0) {
        setEmailNotify(data.emailIdToListen);
      }
    }

    if (data.childMetaCompany != undefined && data.childMetaCompany != "") {
      setChildCompanySelected(data.childMetaCompany);
    }

    if (
      data.recruiterMetaCompany != undefined &&
      data.recruiterMetaCompany != ""
    ) {
      setRecruiterCompanySelected(data.recruiterMetaCompany);
    }

    if (data.peopleToBeNotified != undefined) {
      if (data.peopleToBeNotified.length > 0) {
        setNotify(data.peopleToBeNotified);
      }
    }
    if (open) {
      setCountrySelected(
        data.phone_number_prefix
          ? removePlusFromPhoneNumber(data.phone_number_prefix)
          : countryDetails[0]?.["phone_code"]
      );
      setCountryInternalSelected(
        data.phone_number_internal_prefix
          ? removePlusFromPhoneNumber(data.phone_number_internal_prefix)
          : countryDetails[0]?.["phone_code"]
      );
    }

    // console.log(childCompanySelected)
    if (data.templates != undefined && data.templates != "") {
      var templates = data.templates;
      var updatedTemplates = [];
      // console.log(templates)
      var templateKeys = Object.keys(templates);
      var templateValues = Object.values(templates);
      var paramNumber = "";
      if (templateKeys != []) {
        templateKeys.forEach((val, key) => {
          paramNumber = templateValues[key]["params"].split(",");
          updatedTemplates.push({
            templateIndex: val,
            templateName: templateValues[key]["name"],
            paramNumber: paramNumber.length,
            params: paramNumber,
            verbiage: templateValues[key]["verbiage"],
          });
        });
        // console.log(updatedTemplates)
        setTemplate(updatedTemplates);
      }
      // setTemplate([])

      console.log('+++++++', masterCompanyVal)
    }
    //setToEdit(data);
  }, [data, masterCompanyVal, open, recruiterCompanySelected]);

  const handlePhoneChange = (event) => {
    // console.log(event.target.value)
    let phone_prefix = event.target.value;
    setCountrySelected(removePlusFromPhoneNumber(phone_prefix));
    setPhonePrefix(removePlusFromPhoneNumber(phone_prefix));
  };

  const handlePhoneInternalChange = (event) => {
    // console.log(event.target.value)
    let phone_internal_prefix = event.target.value;
    setCountryInternalSelected(
      removePlusFromPhoneNumber(phone_internal_prefix)
    );
    setPhonePrefixInternal(removePlusFromPhoneNumber(phone_internal_prefix));
  };

  var updatedHiringCompanies = [];
  hiringCompanies.forEach((val, key) => {
    if (val.companyStatus != false) {
      updatedHiringCompanies.push(val);
    }
  });

  hiringCompanies = updatedHiringCompanies;
  // console.log("masterCompanyVal", masterCompanyVal);
  // console.log("dataName",data?.name)
  // console.log(" masterCompanyIdVal", masterCompanyIdVal);
  // console.log("valuex",valuex)

  const templateID = {
    childCompanyID: childCompanyDetails.id,
    recruiterCompanyID: recruiterCompanyDetails.id,
  };

  return (
    <>
      {alert && (
        <ApiAlert
          open={alert}
          snackbaronClose={AlerthandleClose}
          alertonClose={AlerthandleClose}
          alertType={alertStatus ? "success" : "error"}
          Content={alertContent}
        />
      )}
      <Modal
        open={open}
        //onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
                border: "0px solid blue",
              }}
            >
              <Typography
                sx={{
                  // color: "#2B2A2C",
                  fontSize: "30px",
                  fontWeight: 600,
                  color: "primary.darkText",
                }}
              >
                Edit Recruitment Company
              </Typography>
              {openingStatus ? <span className="note">Note: <i>Some fields are disabled because opening is in progress for this company!</i></span>
              : ""}
              <IconButton
                onClick={handleClose}
                sx={{ position: "absolute", top: "0", right: "0" }}
              >
                <CloseIcon />
              </IconButton>
              {openingStatus ?
              <Autocomplete
                id="combo-box-demo"
                options={hiringCompanies}
                sx={{ width: "100%" }}
                getOptionLabel={(option) => option.name}
                defaultValue={masterCompanyVal}
                onChange={(event, value) => setMasterCompanyIdVal(value)}
                className="readClass"
                readOnly
                renderInput={(params, idx) => {
                  // console.log("Params: ", params);
                  return (
                    <TextField
                      {...params}
                      label=<ReuseableLabel label={"Hiring Company"} />
                      error={newSubmit && masterCompanyIdVal === null}
                      helperText={
                        newSubmit &&
                        masterCompanyIdVal === null &&
                        "Hiring Company is required"
                      }
                    />
                  );
                }}
              />
              : 
              <Autocomplete
                id="combo-box-demo"
                options={hiringCompanies}
                sx={{ width: "100%" }}
                getOptionLabel={(option) => option.name}
                defaultValue={masterCompanyVal}
                onChange={(event, value) => setMasterCompanyIdVal(value)}
                renderInput={(params, idx) => {
                  // console.log("Params: ", params);
                  return (
                    <TextField
                      {...params}
                      label=<ReuseableLabel label={"Hiring Company"} />
                      error={newSubmit && masterCompanyIdVal === null}
                      helperText={
                        newSubmit &&
                        masterCompanyIdVal === null &&
                        "Hiring Company is required"
                      }
                    />
                  );
                }}
              />
              }
              {/* <TextField
                sx={{ width: "100%" }}
                label={<ReuseableLabel label={"Recruitment Company Name"} />}
                name="name"
                variant="outlined"
                {...register("name", {})}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              /> */}
              <Controller
                name="name"
                control={control}
                // defaultValue={null}
                // rules={{ required: "Please select an option" }} // Validation rule
                render={({ field }) => (
                {...openingStatus ?
                  <Autocomplete
                    sx={{ width: "100%" }}
                    // value={valuex}
                    defaultValue={data?.name}
                    className="readClass"
                    readOnly
                    onChange={(event, newValue) => {
                      // setIflowCompanyId(masterCompanyIdVal.CompanyId)
                      if (typeof newValue === "string") {
                        setValuex({
                          name: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        handleiFlowData(newValue.inputValue);
                        // Create a new value from the user input
                        setValuex({
                          name: newValue.inputValue,
                        });
                      } else {
                        setValuex(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const { inputValue } = params;

                      const uniqueNames = new Set();

                      const filtered = options.filter((option) => {
                        const inputLowerCase = inputValue.toLowerCase();
                        const optionNameLowerCase = option.name.toLowerCase();

                        if (uniqueNames.has(optionNameLowerCase)) {
                          return false; // Skip this option if the name is a duplicate
                        }
                        uniqueNames.add(optionNameLowerCase);

                        return true;
                      });

                      const matchedOptions = filtered.filter((option) =>
                        option.name
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      );

                      if (
                        inputValue !== "" &&
                        !uniqueNames.has(inputValue.toLowerCase())
                      ) {
                        matchedOptions.push({
                          inputValue,
                          name: `Add "${inputValue}"`,
                        });
                      }

                      return matchedOptions;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={iflowHiringcompanyData}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <ReuseableLabel label={"Recruitment Company Name"} />
                        }
                        {...register("name")}
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                      />
                    )}
                  />
                  : 
                  <Autocomplete
                    sx={{ width: "100%" }}
                    // value={valuex}
                    defaultValue={data?.name}
                    onChange={(event, newValue) => {
                      // setIflowCompanyId(masterCompanyIdVal.CompanyId)
                      if (typeof newValue === "string") {
                        setValuex({
                          name: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        handleiFlowData(newValue.inputValue);
                        // Create a new value from the user input
                        setValuex({
                          name: newValue.inputValue,
                        });
                      } else {
                        setValuex(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const { inputValue } = params;

                      const uniqueNames = new Set();

                      const filtered = options.filter((option) => {
                        const inputLowerCase = inputValue.toLowerCase();
                        const optionNameLowerCase = option.name.toLowerCase();

                        if (uniqueNames.has(optionNameLowerCase)) {
                          return false; // Skip this option if the name is a duplicate
                        }
                        uniqueNames.add(optionNameLowerCase);

                        return true;
                      });

                      const matchedOptions = filtered.filter((option) =>
                        option.name
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      );

                      if (
                        inputValue !== "" &&
                        !uniqueNames.has(inputValue.toLowerCase())
                      ) {
                        matchedOptions.push({
                          inputValue,
                          name: `Add "${inputValue}"`,
                        });
                      }

                      return matchedOptions;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={iflowHiringcompanyData}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <ReuseableLabel label={"Recruitment Company Name"} />
                        }
                        {...register("name")}
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                      />
                    )}
                  />
                }
                )}
              />
              <TextField
                sx={{ width: "100%" }}
                label={<ReuseableLabel label={"Company Website"} />}
                variant="outlined"
                name="website"
                {...register("website", {})}
                error={Boolean(errors.website)}
                helperText={errors.website?.message}
              />
              <Stack
                direction={"row"}
                gap={1}
                spacing={1}
                sx={{ width: "100%" }}
              >
                {openingStatus ? 
                <Autocomplete
                  id="recruiterCompanies"
                  // labelId="recruiterCompanies-label"
                  options={metaCompanyDetails}
                  readOnly
                  className="readClass"
                  getOptionLabel={(option) => option?.name}
                  defaultValue={
                    recruiterMetaData[0] ? recruiterMetaData[0] : ""
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  sx={{
                    width: "50%",
                  }}
                  onChange={(event, value) => {
                    setRecruiterCompanyDetails("");
                    setRecruiterCompanySelected(value?.id ? value.id : "");
                  }}
                  renderInput={(params, idx) => {
                    // console.log("Params: ", params);
                    return (
                      <TextField
                        {...params}
                        label=<ReuseableLabel
                          label={"Meta Company for Recruiter"}
                        />
                        error={newSubmit && recruiterCompanySelected === ""}
                        helperText={
                          newSubmit &&
                          recruiterCompanySelected === "" &&
                          "Meta Company for Recruiter is required"
                        }
                      />
                    );
                  }}
                />
                :
                <Autocomplete
                  id="recruiterCompanies"
                  // labelId="recruiterCompanies-label"
                  options={metaCompanyDetails}
                  getOptionLabel={(option) => option?.name}
                  defaultValue={
                    recruiterMetaData[0] ? recruiterMetaData[0] : ""
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  sx={{
                    width: "50%",
                  }}
                  onChange={(event, value) => {
                    setRecruiterCompanyDetails("");
                    setRecruiterCompanySelected(value?.id ? value.id : "");
                  }}
                  renderInput={(params, idx) => {
                    // console.log("Params: ", params);
                    return (
                      <TextField
                        {...params}
                        label=<ReuseableLabel
                          label={"Meta Company for Recruiter"}
                        />
                        error={newSubmit && recruiterCompanySelected === ""}
                        helperText={
                          newSubmit &&
                          recruiterCompanySelected === "" &&
                          "Meta Company for Recruiter is required"
                        }
                      />
                    );
                  }}
                />
                }
                {openingStatus ? 
                <Autocomplete
                  id="childCompanies"
                  // labelId="childCompanies-label"
                  options={metaCompanyDetails}
                  defaultValue={childMetaData[0] ? childMetaData[0] : ""}
                  getOptionLabel={(option) => option?.name}
                  readOnly
                  className="readClass"
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  sx={{
                    width: "50%",
                  }}
                  onChange={(event, value) => {
                    setChildCompanyDetails("");
                    setChildCompanySelected(value?.id ? value.id : "");
                  }}
                  renderInput={(params, idx) => {
                    // console.log("Params: ", params);
                    return (
                      <TextField
                        {...params}
                        label=<ReuseableLabel
                          label={"Meta Company for Candidate"}
                        />
                        error={newSubmit && childCompanySelected === ""}
                        helperText={
                          newSubmit &&
                          childCompanySelected === "" &&
                          "Meta Company is required"
                        }
                      />
                    );
                  }}
                />
                :
                <Autocomplete
                  id="childCompanies"
                  // labelId="childCompanies-label"
                  options={metaCompanyDetails}
                  defaultValue={childMetaData[0] ? childMetaData[0] : ""}
                  getOptionLabel={(option) => option?.name}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  sx={{
                    width: "50%",
                  }}
                  onChange={(event, value) => {
                    setChildCompanyDetails("");
                    setChildCompanySelected(value?.id ? value.id : "");
                  }}
                  renderInput={(params, idx) => {
                    // console.log("Params: ", params);
                    return (
                      <TextField
                        {...params}
                        label=<ReuseableLabel
                          label={"Meta Company for Candidate"}
                        />
                        error={newSubmit && childCompanySelected === ""}
                        helperText={
                          newSubmit &&
                          childCompanySelected === "" &&
                          "Meta Company is required"
                        }
                      />
                    );
                  }}
                />
                }
              </Stack>
              <Stack
                direction={"row"}
                gap={0.1}
                spacing={2}
                sx={{ width: "100%" }}
              >
              {openingStatus ? 
                <Select
                  id="phone_flag"
                  labelId="phone_flag-label"
                  sx={{ width: "8%" }}
                  defaultValue=""
                  readOnly
                  className="readClass"
                  // value={countrySelected}
                  value={countrySelected}
                  onChange={handlePhoneChange}
                >
                  {countryDetails
                    ? countryDetails.map((res, idx) => {
                        let flagstr = res.iso2;
                        if (flagstr == "UM") {
                          flagstr = "US";
                        }
                        let code =
                          "https://catamphetamine.github.io/country-flag-icons/3x2/" +
                          flagstr +
                          ".svg";
                        let phone_code = "";
                        if (!res.phone_code.includes("+")) {
                          phone_code = "+" + res.phone_code;
                        } else {
                          phone_code = res.phone_code;
                        }

                        if (flag == 1) {
                          menustr = (
                            <MenuItem
                              value={res.phone_code}
                              sx={{ borderBottom: "2px solid #ccc" }}
                              key={idx}
                            >
                              <img
                                src={code}
                                width="25"
                                style={{ marginRight: "6px" }}
                              />{" "}
                              {res.name} ({res.native}) {phone_code}
                            </MenuItem>
                          );
                        } else {
                          menustr = (
                            <MenuItem value={res.phone_code}>
                              <img
                                src={code}
                                width="25"
                                style={{ marginRight: "6px" }}
                              />{" "}
                              {res.name} ({res.native}) {phone_code}
                            </MenuItem>
                          );
                        }

                        if (flag == countryDetails.length - 1) {
                          flag = 0;
                        } else {
                          flag++;
                        }
                        return menustr;
                      })
                    : ""}
                </Select>
                :
                <Select
                  id="phone_flag"
                  labelId="phone_flag-label"
                  sx={{ width: "8%" }}
                  defaultValue=""
                  // value={countrySelected}
                  value={countrySelected}
                  onChange={handlePhoneChange}
                >
                  {countryDetails
                    ? countryDetails.map((res, idx) => {
                        let flagstr = res.iso2;
                        if (flagstr == "UM") {
                          flagstr = "US";
                        }
                        let code =
                          "https://catamphetamine.github.io/country-flag-icons/3x2/" +
                          flagstr +
                          ".svg";
                        let phone_code = "";
                        if (!res.phone_code.includes("+")) {
                          phone_code = "+" + res.phone_code;
                        } else {
                          phone_code = res.phone_code;
                        }

                        if (flag == 1) {
                          menustr = (
                            <MenuItem
                              value={res.phone_code}
                              sx={{ borderBottom: "2px solid #ccc" }}
                              key={idx}
                            >
                              <img
                                src={code}
                                width="25"
                                style={{ marginRight: "6px" }}
                              />{" "}
                              {res.name} ({res.native}) {phone_code}
                            </MenuItem>
                          );
                        } else {
                          menustr = (
                            <MenuItem value={res.phone_code}>
                              <img
                                src={code}
                                width="25"
                                style={{ marginRight: "6px" }}
                              />{" "}
                              {res.name} ({res.native}) {phone_code}
                            </MenuItem>
                          );
                        }

                        if (flag == countryDetails.length - 1) {
                          flag = 0;
                        } else {
                          flag++;
                        }
                        return menustr;
                      })
                    : ""}
                </Select>
              }
              {openingStatus ? 
                <TextField
                  sx={{ width: "48.5%" }}
                  label={
                    <ReuseableLabel label={"WhatsApp Candidate Connect #"} />
                  }
                  variant="outlined"
                  readOnly
                  className="readClass"
                  inputProps={{ maxLength: 15 }}
                  {...register("phone_number", {})}
                  value={
                    childCompanyDetails
                      ? childCompanyDetails.display_phone_number
                      : data.phone_number
                  }
                  error={
                    (conditionCheck == false && phoneNumber?.length == 0) ||
                    Boolean(errors.phone_number)
                  }
                  helperText={
                    conditionCheck == false && phoneNumber?.length == 0
                      ? "This field is required"
                      : errors.phone_number?.message
                  }
                  id={
                    phoneNumber?.length == 0 ? "outlined-error-helper-text" : ""
                  }
                />
                :
                <TextField
                  sx={{ width: "48.5%" }}
                  label={
                    <ReuseableLabel label={"WhatsApp Candidate Connect #"} />
                  }
                  variant="outlined"
                  inputProps={{ maxLength: 15 }}
                  {...register("phone_number", {})}
                  value={
                    childCompanyDetails
                      ? childCompanyDetails.display_phone_number
                      : data.phone_number
                  }
                  error={
                    (conditionCheck == false && phoneNumber?.length == 0) ||
                    Boolean(errors.phone_number)
                  }
                  helperText={
                    conditionCheck == false && phoneNumber?.length == 0
                      ? "This field is required"
                      : errors.phone_number?.message
                  }
                  id={
                    phoneNumber?.length == 0 ? "outlined-error-helper-text" : ""
                  }
                />
              }
              {openingStatus ? 
                <TextField
                  sx={{ width: "60%" }}
                  label={
                    <ReuseableLabel
                      label={"WhatsApp Candidate Connect Meta ID"}
                    />
                  }
                  variant="outlined"
                  inputProps={{ maxLength: 15 }}
                  {...register("phone_number_id", {})}
                  readOnly
                  className="readClass"
                  value={
                    childCompanyDetails
                      ? childCompanyDetails.internal_id
                      : data.phone_number_id
                  }
                  error={
                    (conditionCheck == false && phoneNumberId?.length == 0) ||
                    Boolean(errors.phone_number_id)
                  }
                  helperText={
                    conditionCheck == false && phoneNumberId?.length == 0
                      ? "This field is required"
                      : errors.phone_number_id?.message
                  }
                  id={
                    phoneNumberId?.length == 0
                      ? "outlined-error-helper-text"
                      : ""
                  }
                />
              :
              <TextField
                  sx={{ width: "60%" }}
                  label={
                    <ReuseableLabel
                      label={"WhatsApp Candidate Connect Meta ID"}
                    />
                  }
                  variant="outlined"
                  inputProps={{ maxLength: 15 }}
                  {...register("phone_number_id", {})}
                  value={
                    childCompanyDetails
                      ? childCompanyDetails.internal_id
                      : data.phone_number_id
                  }
                  error={
                    (conditionCheck == false && phoneNumberId?.length == 0) ||
                    Boolean(errors.phone_number_id)
                  }
                  helperText={
                    conditionCheck == false && phoneNumberId?.length == 0
                      ? "This field is required"
                      : errors.phone_number_id?.message
                  }
                  id={
                    phoneNumberId?.length == 0
                      ? "outlined-error-helper-text"
                      : ""
                  }
                />
              }
              </Stack>
              <Stack
                direction={"row"}
                gap={0.1}
                spacing={2}
                sx={{ width: "100%" }}
              >
                {openingStatus ? 
                <Select
                  id="phone_flag_internal"
                  labelId="phone_flag-label"
                  readOnly
                  className="readClass"
                  defaultValue=""
                  value={countryInternalSelected}
                  onChange={handlePhoneInternalChange}
                >
                  {countryDetails
                    ? countryDetails.map((res) => {
                        let flagstr = res.iso2;
                        if (flagstr == "UM") {
                          flagstr = "US";
                        }
                        let code =
                          "https://catamphetamine.github.io/country-flag-icons/3x2/" +
                          flagstr +
                          ".svg";
                        let phone_code = "";
                        if (!res.phone_code.includes("+")) {
                          phone_code = "+" + res.phone_code;
                        } else {
                          phone_code = res.phone_code;
                        }
                        if (flag == 1) {
                          menustr = (
                            <MenuItem
                              value={res.phone_code}
                              sx={{ borderBottom: "2px solid #ccc" }}
                            >
                              <img
                                src={code}
                                width="25"
                                style={{ marginRight: "6px" }}
                              />{" "}
                              {res.name} ({res.native}) {phone_code}
                            </MenuItem>
                          );
                        } else {
                          menustr = (
                            <MenuItem value={res.phone_code}>
                              <img
                                src={code}
                                width="25"
                                style={{ marginRight: "6px" }}
                              />{" "}
                              {res.name} ({res.native}) {phone_code}
                            </MenuItem>
                          );
                        }
                        flag++;
                        return menustr;
                      })
                    : ""}
                </Select>
                :
                <Select
                  id="phone_flag_internal"
                  labelId="phone_flag-label"
                  defaultValue=""
                  value={countryInternalSelected}
                  onChange={handlePhoneInternalChange}
                >
                  {countryDetails
                    ? countryDetails.map((res) => {
                        let flagstr = res.iso2;
                        if (flagstr == "UM") {
                          flagstr = "US";
                        }
                        let code =
                          "https://catamphetamine.github.io/country-flag-icons/3x2/" +
                          flagstr +
                          ".svg";
                        let phone_code = "";
                        if (!res.phone_code.includes("+")) {
                          phone_code = "+" + res.phone_code;
                        } else {
                          phone_code = res.phone_code;
                        }
                        if (flag == 1) {
                          menustr = (
                            <MenuItem
                              value={res.phone_code}
                              sx={{ borderBottom: "2px solid #ccc" }}
                            >
                              <img
                                src={code}
                                width="25"
                                style={{ marginRight: "6px" }}
                              />{" "}
                              {res.name} ({res.native}) {phone_code}
                            </MenuItem>
                          );
                        } else {
                          menustr = (
                            <MenuItem value={res.phone_code}>
                              <img
                                src={code}
                                width="25"
                                style={{ marginRight: "6px" }}
                              />{" "}
                              {res.name} ({res.native}) {phone_code}
                            </MenuItem>
                          );
                        }
                        flag++;
                        return menustr;
                      })
                    : ""}
                </Select>
                }
                {openingStatus ? 
                <TextField
                  sx={{ width: "48.5%" }}
                  label={
                    <ReuseableLabel label={"WhatsApp Recruiter Connect #"} />
                  }
                  variant="outlined"
                  readOnly
                  className="readClass"
                  inputProps={{ maxLength: 15 }}
                  {...register("phone_number_internal", {})}
                  value={
                    recruiterCompanyDetails
                      ? recruiterCompanyDetails.display_phone_number
                      : data.phone_number_internal
                  }
                  error={
                    (conditionCheck == false &&
                      phoneNumberInternal?.length == 0) ||
                    Boolean(errors.phone_number_internal)
                  }
                  helperText={
                    conditionCheck == false && phoneNumberInternal?.length == 0
                      ? "This field is required"
                      : errors.phone_number_internal?.message
                  }
                  id={
                    phoneNumberInternal?.length == 0
                      ? "outlined-error-helper-text"
                      : ""
                  }
                />
                :
                <TextField
                  sx={{ width: "48.5%" }}
                  label={
                    <ReuseableLabel label={"WhatsApp Recruiter Connect #"} />
                  }
                  variant="outlined"
                  inputProps={{ maxLength: 15 }}
                  {...register("phone_number_internal", {})}
                  value={
                    recruiterCompanyDetails
                      ? recruiterCompanyDetails.display_phone_number
                      : data.phone_number_internal
                  }
                  error={
                    (conditionCheck == false &&
                      phoneNumberInternal?.length == 0) ||
                    Boolean(errors.phone_number_internal)
                  }
                  helperText={
                    conditionCheck == false && phoneNumberInternal?.length == 0
                      ? "This field is required"
                      : errors.phone_number_internal?.message
                  }
                  id={
                    phoneNumberInternal?.length == 0
                      ? "outlined-error-helper-text"
                      : ""
                  }
                />
                }
                {openingStatus ? 
                <TextField
                  sx={{ width: "60%" }}
                  label={
                    <ReuseableLabel
                      label={"WhatsApp Recruiter Connect Meta ID"}
                    />
                  }
                  variant="outlined"
                  readOnly
                  className="readClass"
                  inputProps={{ maxLength: 15 }}
                  {...register("phone_number_id_internal", {})}
                  value={
                    recruiterCompanyDetails
                      ? recruiterCompanyDetails.internal_id
                      : data.phone_number_id_internal
                  }
                  error={
                    (conditionCheck == false &&
                      phoneNumberIdInternal?.length == 0) ||
                    Boolean(errors.phone_number_id_internal)
                  }
                  helperText={
                    conditionCheck == false &&
                    phoneNumberIdInternal?.length == 0
                      ? "This field is required"
                      : errors.phone_number_id_internal?.message
                  }
                  id={
                    phoneNumberIdInternal?.length == 0
                      ? "outlined-error-helper-text"
                      : ""
                  }
                />
                :
                <TextField
                  sx={{ width: "60%" }}
                  label={
                    <ReuseableLabel
                      label={"WhatsApp Recruiter Connect Meta ID"}
                    />
                  }
                  variant="outlined"
                  inputProps={{ maxLength: 15 }}
                  {...register("phone_number_id_internal", {})}
                  value={
                    recruiterCompanyDetails
                      ? recruiterCompanyDetails.internal_id
                      : data.phone_number_id_internal
                  }
                  error={
                    (conditionCheck == false &&
                      phoneNumberIdInternal?.length == 0) ||
                    Boolean(errors.phone_number_id_internal)
                  }
                  helperText={
                    conditionCheck == false &&
                    phoneNumberIdInternal?.length == 0
                      ? "This field is required"
                      : errors.phone_number_id_internal?.message
                  }
                  id={
                    phoneNumberIdInternal?.length == 0
                      ? "outlined-error-helper-text"
                      : ""
                  }
                />
                }
              </Stack>
              <Stack
                direction={"row"}
                // gap={2}
                // spacing={2}
                sx={{ width: "100%" }}
              >
                {/* <Autocomplete
                  id="combo-box-demo"
                  options={hiringCompanies}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.name}
                  defaultValue={masterCompanyVal}
                  onChange={(event, value) => setMasterCompanyIdVal(value)}
                  renderInput={(params, idx) => {
                    // console.log("Params: ", params);
                    return (
                      <TextField
                        {...params}
                        label=<ReuseableLabel label={"Hiring Company"} />
                        error={newSubmit && masterCompanyIdVal === null}
                        helperText={
                          newSubmit &&
                          masterCompanyIdVal === null &&
                          "Hiring Company is required"
                        }
                      />
                    );
                  }}
                /> */}
                <TextField
                  sx={{ width: "100%" }}
                  label=<ReuseableLabel label={"Company Abbreviation"} />
                  variant="outlined"
                  {...register("companyForHint", {})}
                  error={Boolean(errors.companyForHint)}
                  helperText={errors.companyForHint?.message}
                />
              </Stack>
              <Stack
                direction={"row"}
                gap={0.1}
                spacing={2}
                sx={{ width: "100%" }}
              >
                <TextField
                  sx={{ width: "50%" }}
                  id="filled-multiline-flexible"
                  name=""
                  label="LinkedIn Page"
                  {...register("linkedinPage")}
                  error={Boolean(errors.linkedinPage)}
                  helperText={errors.linkedinPage?.message}
                  //  style={style}
                  InputProps={{ sx: { borderRadius: "6px" } }}
                />
                <TextField
                  sx={{ width: "50%" }}
                  id="filled-multiline-flexible"
                  name=""
                  label="Instagram Page"
                  {...register("careerPage")}
                  error={Boolean(errors.careerPage)}
                  helperText={errors.careerPage?.message}
                  // style={style}
                  InputProps={{ sx: { borderRadius: "6px" } }}
                />
              </Stack>
              <Stack
                direction={"row"}
                gap={0.1}
                spacing={2}
                sx={{ width: "100%" }}
              >
                <TextField
                  sx={{ width: "50%" }}
                  id="filled-multiline-flexible"
                  name=""
                  label="Career Page"
                  {...register("careerPage")}
                  error={Boolean(errors.careerPage)}
                  helperText={errors.careerPage?.message}
                  // style={style}
                  InputProps={{ sx: { borderRadius: "6px" } }}
                />
                <TextField
                  sx={{ width: "50%" }}
                  id="filled-multiline-flexible"
                  name=""
                  label="X Handle"
                  {...register("xHandle")}
                  error={Boolean(errors.xHandle)}
                  helperText={errors.xHandle?.message}
                  // style={style}
                  InputProps={{ sx: { borderRadius: "6px" } }}
                />
              </Stack>
              <Box
                // direction={"row"}
                // gap={2}
                // spacing={2}
                sx={{ width: "100%" }}
              >
                <FormControl
                  variant="outlined"
                  sx={{ width: "100%" }}
                  // style={{ marginTop: 11, minWidth: 150 }}
                  // size="small"
                  error={Boolean(errors.reapplyDuration)}
                >
                  {/* Path Select Input */}
                  <InputLabel id="demo-simple-select-required-label">
                    {/* Path* */}
                    <ReuseableLabel label={"Reapply Duration"} />
                  </InputLabel>
                  <Controller
                    name="reapplyDuration"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select an option" }}
                    render={({ field }) => (
                      <>
                        <Select
                          {...field}
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          label="Reapply Duration"
                          defaultValue=""
                          // style={{ height: "43px" }}
                          error={Boolean(errors.Path)}
                        >
                          <MenuItem value={""}>
                            Please select an option
                          </MenuItem>
                          <MenuItem value={"0"}>0 day</MenuItem>
                          <MenuItem value={"15"}>15 days</MenuItem>
                          <MenuItem value={"30"}>30 days</MenuItem>
                          <MenuItem value={"90"}>90 days</MenuItem>
                          <MenuItem value={"180"}>180 days</MenuItem>
                          <MenuItem value={"365"}>365 days</MenuItem>
                        </Select>
                        {errors.reapplyDuration && (
                          <FormHelperText error>
                            {errors.reapplyDuration.message}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </Box>
              <Box sx={{ width: "100%" }}>
                <TextField
                  multiline
                  sx={{ width: "100%" }}
                  label="Company Description"
                  variant="outlined"
                  name="description"
                  rows={5}
                  // maxRows={5}
                  inputProps={{ maxLength: 4000 }}
                  {...register("description")}
                  onKeyUp={handleDescriptionKeyUp}
                />
                <span
                  style={{
                    fontSize: "11px",
                    position: "relative",
                    float: "right",
                    right: "4px",
                    margin: "6px 0px 10px",
                    color: `${
                      recruiterCompanyDecLength < 4000 ? "gray" : "red"
                    }`,
                  }}
                >
                  {recruiterCompanyDecLength}/4000
                </span>
              </Box>
              {/* We need to add social media handle */}
              {/* // PEOPLE TO BE NOTIFIED  */}
              <Box sx={{ width: "100%" }}>
                <EmailRepeaters
                  emailFormValues={emailNotify}
                  setEmailFormValues={setEmailNotify}
                  emailValidate={emailValidate}
                  setEmailValidate={setEmailValidate}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <PeopleToNotifyRepeater
                  formValues={notify}
                  setFormValues={setNotify}
                  countryDetails={countryDetails}
                  countrySelected={removePlusFromPhoneNumber(defaultCountry)}
                  repeaterError={repeaterError}
                  setRepeaterError={setRepeaterError}
                />
                {peopleToNotifyAlert && (
                  <span style={{ color: "red" }}>{peopleToNotifyAlert}</span>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Stack direction={"column"}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    // color: "black",
                    color: getThemeColor("#000", "#fff"),
                    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                    mt: "10px",
                  }}
                >
                  Meta Template :
                </Typography>
                {!conditionCheck ? (
                  <span
                    style={{
                      color: "#d32f2f",
                      fontWeight: "400",
                      fontSize: "0.95rem",
                    }}
                  >
                    Please fill the required filed
                  </span>
                ) : (
                  ""
                )}
              </Stack>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  borderBottom: "1px solid ",
                  borderBottomColor: getThemeColor("#000", "#fff"),
                  width: "98%",
                }}
              ></span>
              {templateClick == false ? (
                <span
                  style={{ width: "1%", cursor: "pointer" }}
                  onClick={handleTemplate}
                >
                  {conditionCheck ? (
                    <AddCircleIcon
                      fontSize="medium"
                      sx={{ color: getThemeColor("#000", "#fff") }}
                    />
                  ) : (
                    <Tooltip
                      title="Please fill the required fields"
                      arrow
                      placement="top-end"
                    >
                      <AddCircleIcon
                        fontSize="medium"
                        sx={{ color: getThemeColor("#000", "#fff") }}
                      />
                    </Tooltip>
                  )}
                </span>
              ) : (
                <span
                  style={{ width: "1%", cursor: "pointer" }}
                  onClick={handleTemplate}
                >
                  <RemoveCircleIcon
                    fontSize="medium"
                    sx={{ color: getThemeColor("#000", "#fff") }}
                  />
                </span>
              )}
            </Box>
            <Box sx={{ width: "100%" }}>
              {templateClick == true ? (
                <TemplateRepeater
                  templateValues={template}
                  setTemplateValues={setTemplate}
                  templateID={templateID}
                />
              ) : (
                ""
              )}
            </Box>
            <Box sx={{ width: "100%" }}>
              <Stack
                direction={"row"}
                justifyContent={"right"}
                spacing={2}
                sx={{ width: "100%" }}
              >
                <Button
                  sx={{
                    borderRadius: "6px",
                    // background: "#1E0D61",
                    backgroundColor: "primary.main",
                    color: "#fff",
                    textTransform: "none",
                    "&:hover": {
                      //background: "#220986"
                      backgroundColor: "primary.dark",
                    },
                  }}
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  onClick={handleClose}
                  sx={{
                    borderRadius: "6px",
                    // background: "#FE9A25",
                    backgroundColor: "secondary.main",
                    color: "#fff",
                    textTransform: "none",
                    "&:hover": {
                      //background: "#e9983c"
                      backgroundColor: "secondary.dark",
                    },
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};
